import React from 'react'
import { Image } from 'react-bootstrap'
import "./style.css"

export const Cell = (props: {
    image: string,
    title: string,
    subtitle: string,
    onClick?: () => void
}) => {
    return <div onClick={() => {
        if (!props.onClick) { return }
        props.onClick()
    }} style={{ height: 200, width: 200 }} className="cell" >
        {
            props.image &&
            <Image src={props.image} width={100} height={100} className="tile-image" />
        }
        <div className="tile tile-title">
            {props.title}
        </div>
        <div className="tile tile-subtitle">
            {props.subtitle}
        </div>
    </div>
}