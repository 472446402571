import React from 'react'
import { Col, Row } from 'react-bootstrap'
import "./style.css"

export const Footer = () => {
    return (
        <div>
            <div className="footer" style={{ color: "white", display: "flex" }}>
                <div style={{ background: "#4C7F40", width: "20%" }}>
                    <h2>Las Vegas, Nevada</h2>
                </div>
                <div style={{ background: "rgba(145, 209, 255, 0.47)", width: "65%" }}>
                    <h2><a className="footer-link" href="/contact_us">Contact Us</a></h2>
                </div>
                <div style={{ background: "#BBAA92", width: "15%" }}>

                </div>
            </div>
            <Row className="footer" style={{ color: "white" }}>
                <Col md="9" style={{ background: "#7F4062" }}>
                    <h2>Ancient Environmentally Aware Methods</h2>
                </Col>
                <Col md="3" style={{ background: "#16105A" }}>
                    <a className="footer-link" rel="noreferrer" target="_blank" href='https://docs.google.com/forms/d/e/1FAIpQLScolif-ap27Tez0bYbpOYTSU8IRCDgtBCZqCNQy1gjXJci9Ig/viewform'><h2>Join Us</h2></a>
                </Col>
            </Row>
            <Row className="footer" style={{ color: "white" }}>
                <Col md="2" style={{ background: "black" }}>
                    <a className="footer-link" href="/"><h2>gobekli</h2></a>
                </Col>
                <Col md="8" style={{ background: "#16105A" }}>
                    <a className="footer-link" href="/terms_and_conditions"><h2>Terms of Use</h2></a>
                </Col>
                <Col md="2" style={{ background: "rgba(223, 219, 116, 0.30)" }}>
                    <a className="footer-link" href="/privacy_policy"><h2>Privacy</h2></a>
                </Col>
            </Row>
        </div>
    )



}

