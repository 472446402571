import React from 'react'
import { Header } from '../../components/header'
import { Main } from '../../components/main'
import { Cell } from '../../components/cell'
import { CellLayout } from '../../components/cellLayout'
import { Manifesto } from '../../components/manifesto'
import { AboutUs } from '../../components/aboutus'
import { Footer } from '../../components/footer'
import { useNavigate } from 'react-router-dom'

export const Home = () => {
    const lasVegasSrc = "/images/localities/las-vegas.png"
    const seattleSrc = "/images/localities/seattle.png"
    const parisSrc = "/images/localities/paris.png"

    return (
        <>
            <Header />
            <Main />

            {/* LOCALITIES */}
            <div className="my-4" style={{ textAlign: "center" }}>
                <h3 className="section-title">localities</h3>
            </div>
            <CellLayout cellProps={
                [
                    {
                        image: "/images/localities/san-francisco.png",
                        title: "san francisco",
                        subtitle: "usa"
                    }, {
                        image: "/images/localities/chicago.png",
                        title: "chicago",
                        subtitle: "usa"
                    }, {
                        image: "/images/localities/new-york-city.png",
                        title: "new york city",
                        subtitle: "usa"
                    }, {
                        image: "/images/localities/los-angeles.png",
                        title: "los angeles",
                        subtitle: "usa"
                    }, {
                        image: "",
                        title: "",
                        subtitle: ""
                    }, {
                        image: "",
                        title: "",
                        subtitle: ""
                    }, {
                        image: "",
                        title: "",
                        subtitle: ""
                    }, {
                        image: "",
                        title: "",
                        subtitle: ""
                    }, {
                        image: "",
                        title: "",
                        subtitle: ""
                    }, {
                        image: "",
                        title: "",
                        subtitle: ""
                    }, {
                        image: "",
                        title: "",
                        subtitle: ""
                    }, {
                        image: "",
                        title: "",
                        subtitle: ""
                    }, {
                        image: "",
                        title: "",
                        subtitle: ""
                    }
                ]
            } />

            {/* TRADES */}
            <div className="my-4" style={{ textAlign: "center" }}>
                <h3 className="section-title">trades</h3>
            </div>
            <CellLayout cellProps={
                [
                    {
                        image: "/images/trades/woodworking.png",
                        title: "woodworking",
                        subtitle: ""
                    },
                    {
                        image: "/images/trades/pottery.png",
                        title: "pottery",
                        subtitle: ""
                    },
                    {
                        image: "/images/trades/textiles.png",
                        title: "textiles",
                        subtitle: ""
                    },
                    {
                        image: "/images/trades/industrial-design.png",
                        title: "industrial\ndesign",
                        subtitle: ""
                    },
                    {
                        image: "/images/trades/design-services.png",
                        title: "design\nservices",
                        subtitle: ""
                    }, {
                        image: "/images/trades/hygiene.png",
                        title: "hygiene",
                        subtitle: ""
                    }, {
                        image: "/images/trades/leather.png",
                        title: "leather",
                        subtitle: ""
                    }, {
                        image: "/images/trades/jewelry.png",
                        title: "jewelry",
                        subtitle: ""
                    }, {
                        image: "/images/trades/art.png",
                        title: "art",
                        subtitle: ""
                    }, {
                        image: "/images/trades/clothing.png",
                        title: "clothing",
                        subtitle: ""
                    }]
            } />
            <Manifesto />
            <AboutUs />
            <div className="my-5" style={{ textAlign: "center" }} />

            <Footer />
        </>
    )
}