import React from 'react'
import './App.css';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { Home } from './pages/home';
import { Artists } from './pages/artists';
import { Artist } from './pages/artist';
import TermsAndConditions from './pages/terms_and_conditions';
import PrivacyPolicy from './pages/privacy_policy';
import ContactUs from './pages/contact_us';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" Component={Home} />
        <Route path="/artists" Component={Artists} />
        <Route path="/artist" Component={Artist} />
        <Route path='/terms_and_conditions' Component={TermsAndConditions} />
        <Route path='/privacy_policy' Component={PrivacyPolicy} />
        <Route path='/contact_us' Component={ContactUs} />
      </Routes>

    </Router>
  );
}

export default App;
