import React from 'react'
import { Container } from 'react-bootstrap'
import { Header } from '../../components/header'
import { Footer } from '../../components/footer'
import "./style.css"

const ContactUs = () => {
    return (
        <>
            <Header />
            <Container>
                <h1 style={{ textAlign: "center" }}>Contact Us</h1>
                <h4>Email: <a href="mailto:elliot@gobekli.xyz">elliot@gobekli.xyz</a></h4>
                <h4>Email: <a href="mailto:haileyann@gobekli.xyz">haileyann@gobekli.xyz</a></h4>
                <div className="mt-5">If you have any questions, concerns, or feedback, please feel free to reach out to us via email. We are here to help and will get back to you as soon as possible.</div>
                <div>For more information about our services, you can also visit our <a href="/faq">FAQ page</a>.</div>
                <div>Our office is located in Las Vegas, Nevada. If you would like to visit us in person, please contact us to schedule an appointment.</div>
                <div>Thank you for choosing Gobekli. We look forward to hearing from you!</div>
            </Container>
            <Footer />
        </>
    )
}

export default ContactUs