import React, { useEffect } from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import { Header } from '../../components/header'
import "./style.css"

export const Artist = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>
            <Header />
            <Container>
                <h4>UGLY PEOPLE MAKE ALRIGHT STUFF INC.</h4>
                <a href="https://www.unsplash.com" className="link">WEBSITE</a>
                <div style={{ padding: 5 }} />
                <a href="https://x.com/" className="link">SOCIALS</a>
                <Row style={{ marginTop: "100px", marginBottom: "100px" }}>
                    <Col>
                        <Image src="/images/placeholders/image-1.png" alt="artist" />
                    </Col>
                    <Col style={{ justifyContent: "center", alignContent: "center", alignItems: "center" }}>
                        <Image src="/images/placeholders/image-2.png" alt="artist" />
                    </Col>
                    <Col>
                        <Image src="/images/placeholders/image-3.png" alt="artist" />
                    </Col>
                </Row>
                <Row>
                    <Col md="3" xs="12">
                        <a href="email:test@test.com" className="link">CONTACT OWNER</a>
                    </Col>
                    <Col md="9" xs="12">
                        <Row>
                            <Col style={{ textAlign: "center", justifyContent: "center", alignContent: "center", alignItems: "center" }}>
                                <span>ABOUT US</span>
                            </Col>
                            <Col>
                                <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</span>
                            </Col>
                        </Row>
                    </Col>
                </Row>

            </Container>
        </div>

    )
}